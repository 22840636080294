import { Modal, ModalBody, ModalFooter, ModalHeader } from '@msdyn365-commerce-modules/utilities';
import * as React from 'react';

export interface IDobbiesModalProps {
    modalBodySlots?: React.ReactNode[];
    modalBodyElement?: JSX.Element;
    isOpen: boolean;
    className: string;
    modalTitle: JSX.Element;
    modalFooter: JSX.Element;
}

const DobbiesModal: React.FC<IDobbiesModalProps> = (props: IDobbiesModalProps) => {
    return (
        <Modal isOpen={props.isOpen} applicationNode={'renderPage'} autoFocus={true} className={`dobbies-modal ${props.className}`}>
            {props.modalTitle && <ModalHeader> {props.modalTitle}</ModalHeader>}
            {props.modalBodySlots && <ModalBody className='dobbies-modal-body'>{props.modalBodySlots}</ModalBody>}
            {props.modalBodyElement && <ModalBody className='dobbies-modal-body'>{props.modalBodyElement}</ModalBody>}
            {props.modalFooter && <ModalFooter>{props.modalFooter}</ModalFooter>}
        </Modal>
    );
};

export default DobbiesModal;
